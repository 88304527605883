header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $textDefault;
  box-sizing: border-box;
  padding: 0 20px;
}
.logo{
  display: inline-block;
  height: 30px;
  margin-right: 20px;
  img{
    display: inline-block;
    height: 100%;
    width: auto;
  }
}

.nav-item-container{
  position: relative;
  padding: 0 5px;
  height: 50px;
  margin: 0 5px;
}
.nav-item,
.nav-item-parent{
  display: inline-block;
  color: #fff;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight:500;
  text-decoration: none;
  position: relative;
  padding-right: 15px;
  @include transition(all 0.2s ease-out);
  &:hover,
  &.active{
    color: $greenChart;
    .icon{
      @include transition(all 0.2s ease-out);
      background-color: $greenChart !important;
    }
    &:after{
      background-color: $greenChart;
    }
  }
  .icon{
    display: inline-block;
    width: 30px;
    height: 30px; 
    background-color :#fff;
    margin: 10px 5px 10px 0;
    vertical-align: top;
    &.cm{
      @include maskImage('../assets/img/conversation-manager-logo-small.svg');
    }
  }
}
.nav-item-parent{
  &:after{
    content: "";
    display: inline-block;
    width: 12px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 20px;
    right: 0;
    @include maskImage('../assets/img/arrow-menu.svg');
  }
  &:hover{
    &:after{
      background-color: $greenChart;
    }
  }
}
.nav-item-dropdown.visible{
  position: absolute;
  top: 45px;
  left: 0;
  padding: 10px 15px 10px 10px;
  box-sizing: border-box;
  background-color: #fff;
  @include transition(all 0.2s ease-out);
  @include boxShadow(0, 2px, 4px, 0px, rgba(0,0,0,0.2));
  @include borderRadius(10px);
  box-sizing: border-box;
}
.nav-img{
  display: inline-block;
  height: 30px;
  width: 30px;
}
#header-nav-products{
  width: 650px;
  flex-wrap: wrap;
}

.nav-card{
  width: 280px;
  height: 100px;
  margin: 5px;
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  @include transition(all 0.2s ease-out);
  &.apis{
    &:hover{
      background-color: $blueLight;
    }
  }
  &.linto{
    &:hover{
      background-color: $purpleLight;
    }
  }
  &.meeting{
    &:hover{
      background-color: $greenLight;
      
    }
  }
}

.nav-card-link{
  display: inline-block;
  text-decoration: none;
  @include transition(all 0.2s ease-out);
  .title{
    height: 25px;
    align-items: center;
    padding-bottom: 5px;
    .icon{
      display :inline-block;
      width: 25px;
      height: 25px;
      margin: 0 5px;
    }
    .label{
      display :inline-block;
      font-size: 16px;
      font-weight: 500;
      &.apis{
        color: $blueChart;
      }
      &.linto{
        color: $purpleChart;
      }
      &.meeting{
        color: $greenChart;
      }
    }
  }
  .description {
    p{
      font-size: 12px;
      color: #777;
      line-height: 16px;
      margin: 0;
    }
  }

}

.nav-icon-link{
  text-decoration: none;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 7px 0 ;
  .icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  .label{
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    color: $textDefault;
    font-weight: 400;
    strong{
      font-weight: 600;
    }
  }
  &:hover{
    &.apis{
      .label{
        color:$blueChart;
      }
    }
    &.linto{
      .label{
        color:$purpleChart;
      }
    }
    &.meeting{
      .label{
        color:$greenChart;
      }
    }
  }
}


.icon{
  &.apis{
    @include maskImage('../assets/img/apis-icon.svg');
    background-color: $blueChart;
  } 
  &.linto{
    @include maskImage('/assets/img/linto-agent-icon.svg');
    background-color: $purpleChart;

    &.white{
      background-color: #fff;
    }
  }
  &.meeting{
    @include maskImage('/assets/img/linto-meeting-icon.svg');
    background-color: $greenChart;
  }
}

#burger-menu{
  display: none;
}

#burger-menu-button{
  display: inline-block;
  position: fixed;
  top: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  background: #333;
  z-index: 999;
  border: none;
  cursor: pointer;
  @include transition(all 0.2s ease-out);
  &.opened{
    @include maskImage('../assets/img/close.svg');
  }
  &.closed {
    @include maskImage('../assets/img/burger-menu.svg');
  }
  &:hover{
    background-color: $greenChart;
  }
  
}
#burger-menu-navigation{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 888;

}
.burger-nav-item{
  display: inline-block;
  color: #111;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 0;
  @include transition(all 0.2s ease-out);
  &:hover{
    color: $greenChart;
    text-decoration: underline;
  }
}