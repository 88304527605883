.form-field{
  padding: 5px 0;
  margin: 5px 0;

  .label {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 0 0 5px 0;
    i{
      font-weight: 600;
      color: $redChart;
    }
  }
  input{
    display: inline-block;
    border: 1px solid #ccc;
    @include borderRadius(5px);
    font-size: 15px;
    color: $textDefault;
    min-width: 160px;
    padding: 10px;
    background-color: #f2f2f2;
    &.error{
      border-color: $redChart;
    }
  }
  textarea{
    font-size: 15px;
    color: $textDefault;
    background-color: #f2f2f2;
    display: inline-block;
    border: 1px solid #ccc;
    min-height: 80px;
    line-height: 1.5em;
    &.error{
      border-color: $redChart;
    }
  }
  .error-field{
    color: $redChart;
    font-size: 12px;
    padding: 3px 0;
    font-style: italic;
  }
}
.contact-item{
  padding: 5px 0;
  margin: 10px 0;
  .icon{
    display: inline-block;
    width: 30px;
    height: 30px;
    background: $greenChart;
    margin: 0 5px;
    &.mail{
      @include maskImage('../assets/img/mail.svg');
    }
    &.phone{
      @include maskImage('../assets/img/phone.svg');
    }
    &.map{
      @include maskImage('../assets/img/pin.svg');
    }
  }
  .info{
    font-size: 16px;
    a{
      font-weight: 500;
      color: $textDefault;
      text-decoration: underline;
      &:hover{
        color: $greenChart;
      }
    }
  }
}

.mapouter{
  position:relative;
  text-align:right;
  height:400px;
  width:auto;
}
.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:400px;
  width:auto;
  box-sizing: border-box;
}

.contact-notif{
  padding: 10px 20px;
  margin: 10px 0;
  @include borderRadius(5px);  
  color:  #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  a{
    display: inline;
    &:hover{
      text-decoration: underline;
    }
  }
  &.success{
    background-color: $greenChart;  
  }
  &.error{
    background-color: $redChart;  
  }
}