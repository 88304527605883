.homepage-links-container{
  margin: 20px 0;
  height: 380px;
  justify-content: space-evenly;
}


.homepage-link{
  display: inline-block;
  background-color: #fff;
  padding: 10px 15px;
  border: 1px solid #ccc; 
  text-decoration: none;
  position: relative;
  text-align: left;
  @include boxShadow(0,2px,2px,0,rgba(0,0,0,0.2));
  @include transition(all 0.2s ease-out);
  @include borderRadius(10px);
  &>*{
    @include transition(all 0.2s ease-out);
  }
  &:after{
    content: "";
    display: inline-block;
    height: 30px;
    width: 30px;
    position: absolute;
    top:10px;
    right: 20px;
    @include maskImage(' ../assets/img/arrow-menu.svg');
    @include transition(all 0.2s ease-out);
    @include transform(rotate(-90deg));
  }
  
  .icon{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    vertical-align: top;
  }
  .label{
    display: inline-block;
    height: 30px;
    line-height: 30px;
    vertical-align: top;
    font-weight: 500;
    font-size: 16px;
  }
  
  &.apis{
    border-color: $blueChart;
    &:after{
      background-color: $blueChart;
    }
    .label{
      color: $blueChart;
    }
    &:hover{
      background-color: $blueChart;
    }
  }
  &.linto{
    border-color: $purpleChart;
    &:after{
      background-color: $purpleChart;
    }
    .label{
      color: $purpleChart;
    }
    &:hover{
      background-color: $purpleChart;
    }
  }
  &.meeting{
    border-color: $greenChart;
    &:after{
      background-color: $greenChart;
    }
    .label{
      color: $greenChart;
    }
    &:hover{
      background-color: $greenChart;
    }
  }

  &:hover{
    cursor: pointer;
    .label{
      color: #fff
    }
    .icon{
      background-color: #fff
    }
    &:after{
      top: 13px;
      @include transform(rotate(0deg));
      background-color: #fff;
    }
  }
  .homepage-link-detail{
    height: 0;
    overflow: hidden;
    .detail{
      margin: 10px 0;
      color: #fff;
      font-size: 15px;
      line-height: 1.3em;
      font-weight: 500;
    }
    .detail-btn{
      margin: 5px 0;
      align-items: center;
      justify-content: space-evenly;
      .btn-cta{
        margin: 5px 0;
      }
    }
    
  }
  &.active{
    height: auto;
    .homepage-link-detail{
      height: 150px;
    }
    .label{
      color: #fff
    }
    .icon{
      background-color: #fff
    }
    &.apis{
      background-color: $blueChart;
    }
    &.linto{
      background-color: $purpleChart;
    }
    &.meeting{
      background-color: $greenChart;
    }
    &:after{
      top: 13px;
      @include transform(rotate(0deg));
      background-color: #fff;
    }
  }

  &.div{
    height: auto;
    &:after{
      display: none;
    }
    .homepage-link-detail{
      height: auto !important;
    }
    .detail{
      margin: 10px 0;
      color: #fff;
      font-size: 15px;
      line-height: 1.3em;
      font-weight: 400;
    }
    .homepage-link-detail{
      height: auto;
    }
  }
}

.homepage-illustration{
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 380px;
}


#homepage-privacy{
  position: relative;
  z-index: 1;
  background-color: rgb(0,175,154);
  background: linear-gradient(140deg, rgba(0,175,154,0.8) 0%, rgba(165,242,228,0.6) 100%);
  &:after{
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(' ../assets/img/bg-rounded.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    z-index: 2 !important;
    
  }
}
.github-logo{
  display: inline-block;
  width: 42px;
  height: 42px;
  margin: 0 10px 0 0;
  vertical-align: top;
  @include maskImage(' ../assets/img/github-logo-dark.svg');
  background-color: #111;

  &:hover{
    background-color: #777;

  }
}

.homepage-animation-mask-container{
  margin: 0 auto;
}
#homepage-animation-mask{
  width: 100%;
  height: 400px;
  width: 400px;
  position: relative;
  
  background-repeat: no-repeat;
  background-position: center center;
  &.bordered{
    border: 2px solid #ECECEC;
    @include boxShadow(0,2px, 4px, 0, rgba(0,0,0,0.3));
  }

  &.default {
    background-image:url('../assets/img/illustration-homepage-01.svg');
    background-size: contain;
    
  }
  &.smart-meeting{
    background-image:url('../assets/img/screenshot-conversation-manager.png');
    background-size: 300% auto;
    @include moveImgBg(30s);
  }

  &.linto-agent{
    background-image:url('../assets/img/screenshot-linto-ui.png');
    background-size: contain;
    background-position: center center

  }
  &.fading-out{
    @include fade-out()
  }
  &.fading-in{
    @include fade-in()
  }
}