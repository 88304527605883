@import "mixins";
@import "global";
@import "header";

@import "homepage";
@import "cognitiveapis";
@import "demoapis";
@import "demolintoagent";
@import "pricing";
@import "contact";
@import "footer";
@import "responsive";
