@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto+Slab:wght@400;500;600&display=swap');

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  display: block;
}
body {
  line-height: 1;
}
ol,ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
a{
  text-decoration: none;
}
/*===== END RESET CSS =====*/

/*===== CSS VARIABLES =====*/

  $textDefault: #1b1b1b;
  $greyChart: #545454;
  $greenChart: #5FBF9D;
  $greenLight: #5fbf9d36;
  $blueChart: #349EDA;
  $blueBtn: #0081C9;
  $blueLight: #349dda2f;
  $purpleChart: #907DFF;
  $purpleLight: #907dff33;
  $redChart: #e94c47;



/*===== END CSS VARIABLES =====*/
/*===== GLOBAL =====*/
html {
  min-height: 100%;
  height: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  min-height: 100%;
  height: auto;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.3em;
  color: $textDefault;
  background-color: #fff;
}
.flex {
  display: flex;
  &.row{
  flex-direction: row;

  }
  &.col{
  flex-direction: column;

  }
  &.wrap{
  flex-wrap: wrap;

  }
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end{
    justify-content: flex-end;
  }
  &.space-evenly{
    justify-content: space-evenly;
  }
}
.flex1 {
  flex: 1;
  max-width: 100%;
}
.flex2 {
  flex: 2;
  max-width: 100%;
}
.flex3 {
  flex: 3;
  max-width: 100%;
}
.flex4 {
  flex: 4;
  max-width: 100%;
}
.padding-20{
  padding: 20px;
}
.hidden{
  display: none !important;
}

/* BODY */
#body{
  padding: 60px 0 40px 0;
}

section {
  padding: 60px 0;
}
.container{
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 15px;
  z-index: 5;
  box-sizing: border-box;
  &.big{
    max-width: 1200px;
  }
}

h1, 
h2, 
h3, 
h4, 
h5{
  &.green{
    color: $greenChart;
  }
  &.blue {
    color: $blueChart;
  }
  &.purple{
    color: $purpleChart;
  }
}

.big-title{
  display: inline-block;
  width: 100%;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.2em;
  text-align: left;
  padding-bottom: 20px;

  &.centered{
    text-align: center;
  }
  strong{
    &.green{
      color: $greenChart;
    }
    &.blue {
      color: $blueChart;
    }
    &.purple{
      color: $purpleChart;
    }
    &.white{
      color: #fff;
    }
  }
}


h3{
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  padding: 20px 0;
}

.content-white-80{
  padding: 20px;
  @include borderRadius(10px);
  background-color: rgba(255,255,255,0.8);
}

ul{
  margin: 0;
  padding: 0;
  li{
    display: inline-block;
    padding: 7px 5px 7px 25px;
    position: relative;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    box-sizing: border-box;
    a{
      color: $blueChart;
      &:hover{
        color: $blueBtn;
        text-decoration: underline;
      }
    }
    &:before{
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0;
      top: 13px;
      @include maskImage('../assets/img/check.svg');
      background-color: $greenChart;
    }
    &.cross{
      &:before{
        @include maskImage('../assets/img/close.svg');
        background-color: $redChart;
      }
    }
  }
}

p {
  display: inline-block;
  margin: 20px 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  &.thin{
    font-weight: 400;
  }
  &.text-centered{
    text-align: center;
  }
  strong{
    font-weight: 600;
  }
}

.btn-cta-container{
  margin: 10px 0;
}
.btn-cta{
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid;
  font-size: 15px;
  color: #fff;
  max-width: 200px;
  font-weight: 600;
  text-transform: uppercase;
  @include borderRadius(40px);
  @include boxShadow(0,2px,2px,0px,rgba(0,0,0,0.2));
  @include transition(all 0.2s ease-out);
  i{
    text-transform: none;

  }
  &.dark{
    background-color: $textDefault;
    border-color: $textDefault;
    &:hover{
      color: $textDefault;
    }
    
  }
  &.blue{
    background-color: $blueBtn;
    border-color: $blueBtn;
    &:hover{
      color: $blueBtn;
    }
  }
  &:hover{
    cursor: pointer;
    @include boxShadow(0,2px,2px,0px,rgba(0,0,0,0.4));
    background-color: #fff;
  }
  &.greenchart,
  &.purplechart,
  &.bluechart{
    background-color: #fff;
  }
  &.greenchart{
    color: $greenChart;
    &:hover{
      color: #fff;
      background-color: #ffffff2b;
    }
  }
  &.bluechart{ 
    color: $blueChart;
    &:hover{
      color: #fff;
      background-color: #ffffff2b;
    }
  }
  &.purplechart{
    color: $purpleChart;
    &:hover{
      color: #fff;
      background-color: #ffffff2b;
    }
  }

  &.homepage{
    font-size: 14px;
  }
    

}
.disclaimer{
  padding: 20px;
  color: $redChart;
  border: 2px dashed $redChart;
  background-color: #efefef;
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0;
}

.content-img{
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 200px;
}