
.apis-schema-item{
  padding: 20px;
  border: 1px solid $blueChart;
  @include borderRadius(10px);
  width: 80%;
  
}
.apis-schema-img{
  display: inline-block;
  width: 80%;
  height: auto;
  &.brain{
    max-width: 160px;
  }
}